import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { addFeatureFlagAccessed } from '@atlassian/ufo-feature-flags-accessed';
import { init } from '@atlassian/ufo-interaction-metrics-init';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';
export function initUFO(_ref) {
  let {
    analyticsClient,
    additionalConfig,
    featureFlags
  } = _ref;
  const config = {
    product: (additionalConfig === null || additionalConfig === void 0 ? void 0 : additionalConfig.product) || 'marketplace',
    region: 'unknown',
    kind: {
      hover: 0,
      legacy: 0,
      page_load: 1,
      press: 1,
      transition: 1,
      typing: 0
    },
    namePrefix: 'ufo-',
    segmentPrefix: 'ufo-',
    capability: {
      feature_flag_access: 1,
      react_profiler: 1
    }
  };
  if (!config) {
    return;
  }

  // Use this cautiously, as this will increase the payload size. So no every flag needs to be passed to react-ufo
  if (featureFlags) {
    Object.entries(featureFlags).forEach(_ref2 => {
      let [flagKey, flagValue] = _ref2;
      addFeatureFlagAccessed(flagKey, flagValue);
    });
  }
  const getAnalyticsWebClient = () => {
    return new Promise(resolve => {
      resolve(analyticsClient);
    });
  };
  init(getAnalyticsWebClient(), _objectSpread({}, config));
  traceUFOPageLoad();
}